/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import {defaultOptions} from "@atomic-layout/core"
import Layout from "atomic-layout"
import * as React from 'react'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'
import 'gatsby-plugin-prismic-previews/dist/styles.css'

// You can delete this file if you're not using it
export const onClientEntry = () => {
  let options = defaultOptions;
  
  // Example of how to create a wider xl max width
  // options.breakpoints.xl.minWidth = '1600px'
  
  Layout.configure(options)
}

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider>{element}</PrismicPreviewProvider>
)

// export const shouldUpdateScroll = ({
//                                 routerProps: { location },
//                                 getSavedScrollPosition
//                               }) => {
//  
//   const currentPosition = getSavedScrollPosition(location)
//  
//   if(location?.state?.noScroll){
//     return false;
//   }
//
//   window.scrollTo(...(currentPosition || [0, 0]))
// }
